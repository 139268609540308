/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/** APP - INIZIO **/
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {--ion-font-family: 'Barlow'; font-size: 16px; font-weight: 400; color: #282f31;}
h1, p {margin: 0;}
p {margin-bottom: 5px;}

.no-padding {padding: 0 !important;}
.no-margin {margin: 0 !important;}

ion-toolbar, ion-header {--min-height: 70px; --background: #000; background-color: #000;}
ion-toolbar ion-buttons {position: absolute;}
ion-grid {padding: 10px; color: #282f31;}
ion-popover {--backdrop-opacity: .8 !important;}
ion-select {--background-color: #282F31; margin-bottom: 10px; border-radius: 3px; font-size: 18px; height: 50px; color: #fff !important; --placeholder-opacity: 1 !important;}
ion-input {--background-color: #282F31; /*margin-bottom: 10px;*/ border-radius: 3px; font-size: 18px; height: 50px;}
ion-button {--background-activated: #282f31;}
ion-item.c2i-radio-payment {--background: #1B2021;}
ion-list {margin-top: 20px; margin-bottom: 40px;}
ion-item.c2i-radio-payment ion-label {font-weight: normal; font-size: 18px !important; line-height: 20px; text-align: left; color: #fff !important; white-space: break-spaces !important;}
ion-item.c2i-radio-payment ion-label small {font-weight: normal; font-size: 16px !important; line-height: 18px; text-align: left; color: #fff !important;}
ion-item.c2i-input {--background: #282F31; margin-bottom: 10px; font-weight: normal; font-size: 16px; line-height: 20px; text-align: left; color: #fff !important;}
ion-checkbox {--background: #282F31; --border-color: transparent; --checkmark-color: #fff; --background-checked: #282F31; --border-color-checked :#282F31; padding-bottom: 1px;}
ion-radio {background-color: #282F31; border-radius: 100%; width: 20px; height: 20px; --color-checked: #fff; /*padding-bottom: 3px;*/}
ion-title {text-align: center;}
ion-back-button {--color: #fff !important; min-width: 28px !important; --padding-start: 0 !important; --padding-end: 0 !important; padding-top: 10px;}
ion-footer ion-button.button-disabled {--background: #000 !important; --color: #8ea1a8 !important; --opacity: 1 !important; opacity: 1 !important;}
ion-footer ion-button.button-disabled ion-icon {opacity: .50;}
ion-footer ion-button.button-disabled ion-label {opacity: .50;}
#c2i-toolbar-header ion-button.button-disabled {--background: #000 !important; --color: #8ea1a8 !important; --opacity: 1 !important; opacity: 1 !important;}
#c2i-toolbar-header ion-button.button-disabled ion-icon {opacity: .50;}
#c2i-toolbar-header ion-button.button-disabled ion-label {opacity: .50;}

.popover-viewport ion-list-header {color: #282f31; font-size: 40px !important; font-family: "Amatic SC" !important; font-weight: bold !important; text-align: center; padding-top: 15px; padding-right: 20px; --display: block !important;}
.popover-viewport ion-list {background-color: #fff; padding-bottom: 30px; border-radius: 3px; color: #282f31; padding-right: 15px;}
.popover-viewport ion-item {--background: #fff; color: #282f31 !important; /*padding-right: 20px;*/ --color-hover: #282f31; --background-hover: #fff;}
.popover-viewport ion-item:hover {color: #282f31 !important; font-weight: 700;}
.popover-viewport ion-radio {background-color: #fff; border-radius: 100%; width: 20px; height: 20px; --color-checked: #282f31; padding-bottom: 0; border: 1px solid #ccc !important;}


.c2i-text-wp p img.alignleft {float: left; margin-right: 15px;}
.c2i-text-wp p img.aligncenter {display: block; margin: 0 auto; margin-bottom: 15px;}
.c2i-text-fornitori a {color: #fff !important;}

.c2i-img-cover {display: block; width: 100vw; height: 100vh; object-fit: cover;}
.c2i-img-contain {display: block; width: 100vw; height: 100vh; object-fit: contain;}
.c2i-img-full {width: 100%;}
.c2i-btn-back {position: absolute;}
a.c2i-link {font-weight: normal; font-size: 16px; line-height: 20px; text-align: left; color: #8ea1a8 !important; text-decoration: none;}
.c2i-no-pointer {cursor: default !important;}
.c2i-hide-mobile {display: none;}

.c2i-error {color: red; font-size: 16px; font-weight: 500; margin: 15px 0;}
.c2i-info {color: green; font-size: 16px; font-weight: 500; margin: 15px 0;}

.c2i-align-center {text-align: center !important;}
.c2i-align-left {text-align: left !important;}

.c2i-mt-25 {margin-top: 25px !important;}

.c2i-height-5 {height: 5px !important;}
.c2i-height-15 {height: 15px !important;}
.c2i-height-25 {height: 25px !important;}
.c2i-height-35 {height: 35px !important;}
.c2i-height-90 {height: 90px !important;}
/** APP - FINE **/


/** UPDATE WEB - INIZIO **/
.radio-icon {border: 0 !important;}
/** UPDATE WEB - FINE **/


/** GLOBALI - INIZIO **/
#c2i-toolbar-header .c2i-footer-button {height: 50px;}
#c2i-toolbar-header .c2i-footer-button ion-icon {width: 25px; margin-right: 10px;}
#c2i-toolbar-header .c2i-footer-button ion-label {width: auto;}

.c2i-img-full-container-home {display: none;}
.c2i-img-banner {max-height: 233px;}
.c2i-img-piatto {max-height: 134px;}
.c2i-img-banner-white {background-color: #fff; width: 100%;}
.c2i-img-banner-white-slider {background-color: transparent; width: 100%; max-height: 450px; max-width: auto;}
.c2i-title {font-family: "Amatic SC"; font-weight: bold; font-size: 40px; text-align: center; color: #282f31;}
h2.c2i-title {font-family: "Amatic SC"; font-weight: bold; font-size: 30px; text-align: center; color: #282f31;}
.c2i-title-white {font-family: "Amatic SC"; font-weight: bold; font-size: 40px; text-align: center; color: #f8f8f8;}
.c2i-button {--ion-color-primary: #5361660; background: #536166; width: 100%; height: 50px; font-weight: 600; --font-size: 18px; --color: #fff; border: 0; border-radius: 3px; margin: 0;}
.c2i-button-edit {--ion-color-primary: #fff; width: 90px; height: 30px; font-weight: 600; --font-size: 16px; --color: #282f31; border: 1px solid #707070; border-radius: 3px; margin: 0;}
.c2i-button-cart {background: #536166 url('./assets/img/icon-cart-btn.svg') no-repeat 15px center;}
.c2i-button-add {background: #fff url('./assets/img/icon-plus-gray.svg') no-repeat 15px center; border: 1px solid #8EA1A8; color: #536166; margin-bottom: 10px;}
.c2i-button-qta {height: 14px; width: 14px; border-radius: 3px; border: 0; text-align: center; background: #536166; padding: 11px; cursor: pointer;}
.c2i-button-link {display: block; text-align: center; padding-top: 15px; letter-spacing: 0.06em; text-decoration: none; background: #536166; width: 100%; height: 50px; font-weight: 600; font-size: 14px; color: #fff; border: 0; border-radius: 3px; margin: 0;}
/*.c2i-button-back {padding-top: 15px;}*/
.c2i-cameriere-icon-container {position: absolute; right: 40px; height: 70px; width: 50px; /*padding-top: 15px;*/}
.c2i-cameriere-icon-container ion-icon {width: 30px; height: 30px;}
.c2i-cameriere-icon-container ion-label {display: block; font-size: 13px !important; font-weight: 500; color: #8ea1a8 !important;}
.c2i-cameriere-icon-container ion-button {height: 70px !important;}
.c2i-selected {color: #F8F8F8 !important;}
.c2i-slide-selected {background-color: #536166 !important; color: #fff !important;}
.c2i-list-disabled {opacity: .25 !important;}
.c2i-page-content {--background: #F8F8F8 !important;}
.c2i-page-content-white  {--background: #FFF !important;}
/*hr.c2i-separator {margin-top: 0; margin-bottom: 25px; background-color: rgba(230, 29, 125, .25);}*/
hr.c2i-separator {margin-top: 0; margin-bottom: 25px; background-color: rgba(142, 161, 168, .25);}
.c2i-textarea {border: 1px solid #CCCCCC; border-radius: 3px; background: #F8F8F8; padding: 5px 15px !important; min-height: 100px;}
.c2i-align-center-white {font-weight: normal; font-size: 18px; line-height: 20px; text-align: center; color: #fff !important; margin-top: 10px;}
.c2i-full-text {font-family: Barlow; font-weight: normal; font-size: 16px; line-height: 18px; text-align: left; color: #beccd1;}
.c2i-full-text p {margin-bottom: 10px;}
.c2i-full-text p img {margin-right: 5px;}
.c2i-full-text-light {color: #f8f8f8 !important;}
.c2i-full-text-white {color: #fff !important;}
.c2i-full-text-link {text-decoration: underline; cursor: pointer;}
.c2i-full-text-link strong {font-size: 18px !important;}
/** GLOBALI - FINE **/


/** HEADER - INIZIO **/
.c2i-header-title {padding-top: 10px;}
/** HEADER - FINE **/


/** LOGIN - INIZIO **/
.c2i-login-content {--background: #000 !important;}
.c2i-login-image {margin: 0 auto; text-align: center; margin: 100px 0 40px 0;}
/** LOGIN - FINE **/


/** HOME - INIZIO **/
.c2i-home-content {--background: #1b2021 !important;}
.c2i-home-content p {color: #beccd1;}
.c2i-home-grid {color: #beccd1;}
.c2i-tipo-consegna-area {border-bottom: 1px solid #8ea1a8; margin-top: 20px;}
.c2i-tipo-consegna {font-family: "Amatic SC"; font-weight: bold; font-size: 28px; text-align: center; color: #8ea1a8; cursor: pointer;}
.c2i-tipo-consegna-selected {border-bottom: 2px solid #F8F8F8;}
.c2i-tipo-consegna-selected .c2i-tipo-consegna {color: #F8F8F8 !important;}
.c2i-select-tavolo {background: #282F31 url("./assets/img/icon-cap.svg") no-repeat 15px center; padding-left: 50px;}
.c2i-select-cap {background: #282F31 url("./assets/img/icon-cap.svg") no-repeat 15px center; padding-left: 50px;}
.c2i-select-data {background: #282F31 url("./assets/img/icon-data.svg") no-repeat 15px center; padding-left: 50px;}
.c2i-select-fascia {background: #282F31 url("./assets/img/icon-fascia.svg") no-repeat 15px center; padding-left: 50px;}
/** HOME - FINE **/


/** CONSEGNA - INIZIO **/
.c2i-edit-grid {background-color: #fff !important;}
.c2i-edit-grid ion-row {max-width: 1070px; margin: 0 auto;}
.c2i-riepilogo-consegna {font-weight: 600; font-size: 16px; line-height: 16px; color: #282f31; text-transform: uppercase;}
.c2i-riepilogo-consegna-button {text-align: right;}
/** CONSEGNA - FINE **/


/** CATGORIE - INIZIO **/
.c2i-categoria {margin-bottom: 25px; cursor: pointer;}
.c2i-categoria-img {text-align: center;}
.c2i-categoria-img img {border-radius: 100%; background-color: #fff;}
.c2i-categoria-nome {font-family: "Amatic SC"; font-weight: bold; font-size: 28px; text-align: center; color: #282f31; line-height: 25px;}
.c2i-categoria-prezzo {font-weight: 600; font-size: 16px; text-align: center; color: #282f31; margin-bottom: 5px; margin-top: 5px;}
.c2i-categoria-portate {font-weight: normal; font-size: 16px; text-align: center; color: #536166; max-width: 80px; margin: 0 auto;}
.c2i-filtro {opacity: .5;}
.c2i-filtro-attivo {opacity: 1;}
/** CATGORIE - FINE **/


/** PIATTI - INIZIO **/
/*.c2i-piatto {padding-bottom: 25px;}*/
.c2i-piatto-img {text-align: center; background-color: #fff; cursor: pointer;}
.c2i-piatto-img-border {border: 1px solid #ccc; border-radius: 3px;}
.c2i-piatto-img img {border-top-left-radius: 3px; border-top-right-radius: 3px; background-color: #fff; width: 100%;}
.c2i-piatto-img-round img {border-radius: 3px;}
.c2i-piatto-txt-area {background-color: #fff; padding: 10px 10px 15px 10px; position: relative; border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;}
.c2i-piatto-desc {font-weight: 500; font-size: 18px; line-height: 18px; color: #282f31; margin-bottom: 15px; min-height: 65px; cursor: pointer;}
.c2i-piatto-desc-no-height {min-height: auto !important;}
.c2i-piatto-prezzo {font-weight: 600; font-size: 16px; line-height: 18px; color: #282f31;}
.c2i-piatto-cart {font-weight: normal; font-size: 16px; text-align: center; color: #536166; position: absolute; right: 10px; bottom: 10px; cursor: pointer;}
.c2i-categorie-slider {margin-bottom: 10px; margin-top: 5px;}
.c2i-categorie-slider .swiper-slide {width: auto;}
.c2i-categorie-slide {background-color: #fff; border-radius: 3px; padding: 10px 15px; font-weight: 500; font-size: 18px; line-height: 16px; text-align: center; color: #1b2021; cursor: pointer;}
.c2i-piatto-dettaglio-title {font-weight: 600; font-size: 24px; color: #282f31;}
.c2i-piatto-dettaglio-subtitle {font-family: Barlow; font-weight: 600; font-size: 18px; line-height: 18px; color: #282f31; border-bottom: 1px solid rgba(230, 29, 125, .25); padding-bottom: 8px; margin-bottom: 8px}
.c2i-piatto-dettaglio-desc {margin-bottom: 15px;}
.c2i-piatto-dettaglio-prezzo {font-weight: 600; font-size: 18px; line-height: 18px; color: #282f31; padding-top: 8px}
.c2i-piatto-dettaglio-note-read {margin: 25px 0;}
.c2i-piatto-dettaglio-qta {text-align: right; font-weight: 600; font-size: 18px; line-height: 18px; color: #282f31;}
.c2i-qta-label {display: inline-block; vertical-align: top; padding-top: 8px; margin-right: 5px;}
.c2i-uscita-label {padding-top: 13px;}
.c2i-qta {border: 1px solid #CCCCCC; border-radius: 3px; font-family: Barlow; font-weight: 600; font-size: 18px; line-height: 18px; color: #282f31; height: 36px; width: 50px; margin-right: 5px; margin-left: 5px; vertical-align: top; display: inline-block; text-align: center !important;}
.c2i-piatto-dettaglio-list ul {list-style: none; padding: 0;}
.c2i-piatto-dettaglio-list ul li {padding-bottom: 5px; font-weight: 600; font-size: 16px; line-height: 18px; color: #282f31;}
ion-select.c2i-select-white {border: 1px solid #CCCCCC; color: #000 !important; display: inline-flex; min-width: 132px;}
/** PIATTI - FINE **/


/** COMPONIBILI - INIZIO **/
.c2i-componibile-prezzo {font-weight: 600; font-size: 16px; line-height: 18px; text-align: center; color: #282f31;}
.c2i-componibile-txt {font-weight: 500; font-size: 16px; line-height: 20px; text-align: center; color: #1b2021; margin-bottom: 25px;}
.c2i-portata-componibile-slot {background-color: #fff; border-radius: 3px;}
.c2i-portata-componibile-titolo {font-weight: 500; font-size: 18px; line-height: 18px; text-align: left; color: #282f31; padding: 10px;}
.c2i-portata-componibile-scegli {padding: 10px 0;}
.c2i-componibile-elimina {font-weight: 600; font-size: 18px; line-height: 18px; text-align: left; color: #282f31; padding-right: 20px; text-align: right;}
.c2i-componibile-elimina img {cursor: pointer;}
.c2i-piatti-slider {margin-bottom: 10px; margin-top: 5px;}
.c2i-piatti-slider .swiper-slide {width: auto; max-width: 178px;}
.c2i-piatti-slide {background-color: #fff; border-radius: 3px; padding: 10px 15px; font-weight: 500; font-size: 18px; line-height: 16px; text-align: center; color: #1b2021;}
.c2i-piatto-txt-area-componibile {min-height: 150px;}
.c2i-piatto-componibile-img-col img {max-width: 90px;}
/** COMPONIBILI - FINE **/


/** CHECKOUT - INIZIO **/
.c2i-ordine-riepilogo {background-color: #fff; border-radius: 3px; padding: 10px 15px; font-weight: 400; font-size: 16px; line-height: 18px; text-align: left; color: #282f31;}
.c2i-ordine-spedizione {background-color: #fff; border-radius: 3px; padding: 10px 15px; font-weight: 400; font-size: 16px; line-height: 18px; text-align: left; color: #282f31;}
.c2i-ordine-riepilogo-icon .c2i-tipo-consegna {float: left; border-right: 1px solid #BECCD1; padding-right: 15px; margin-right: 15px; font-family: "Amatic SC"; font-weight: bold; font-size: 28px; text-align: center; color: #282f31; min-width: 90px;}
.c2i-ordine-riepilogo-icon .c2i-tipo-consegna img {margin-bottom: 10px;}
.c2i-ordine-spedizione-importo {font-size: 18px; float: right; font-weight: 600;}
.c2i-ordine-item-container {background-color: #fff; border-radius: 3px; padding: 15px 5px 5px 5px;}
.c2i-ordine-item-container .c2i-piatto-desc {font-weight: 500; font-size: 18px; line-height: 18px; text-align: left; color: #282f31;}
.c2i-ordine-item-container .c2i-piatto-dettaglio-note-read {font-weight: normal; font-size: 16px; line-height: 20px; text-align: left; color: #282f31; margin: 5px 0 20px 0;}
.c2i-ordine-item-container-top {margin-bottom: 15px !important;}
.c2i-ordine-riepilogo-numero {position: absolute; right: 15px; bottom: 15px;}
.c2i-ordine-elimina {font-weight: 600; font-size: 18px; line-height: 18px; text-align: left; color: #282f31; padding-top: 6px; cursor: pointer;}
.c2i-ordine-elimina img {margin-right: 5px;}
.c2i-subtitle-white {font-weight: normal; font-size: 18px; line-height: 20px; text-align: center; color: #fff; margin-top: 5px;}
.c2i-ordine-menu-titolo {font-weight: 600; font-size: 20px; line-height: 18px; text-align: center; color: #282f31; margin-bottom: 10px;}
.c2i-ordine-menu-prezzo {font-weight: 600; font-size: 16px; line-height: 18px; text-align: center; color: #282f31; margin-bottom: 15px;}
.c2i-ordine-menu-note {font-weight: normal; font-size: 16px; line-height: 20px; text-align: left; color: #282f31; margin-bottom: 25px;}
.c2i-piatto-menu-desc {padding-left: 15px;}
.c2i-ordine-item-menu-container {padding: 25px 10px 25px 10px;}
.c2i-dettaglio {cursor: pointer;}
.c2i-dettaglio-link {font-weight: 400; font-size: 16px; line-height: 18px; text-align: center; color: #282f31; margin-bottom: 15px; text-decoration: none; margin-top: 15px;}
/** CHECKOUT - FINE **/


/** FOOTER - INIZIO **/
ion-footer {height: 75px; --background: #000;}
.c2i-footer-button {width: 20%; margin: 0; --border-radius: 0; --background: #000; height: 75px; --box-shadow: none;}
.c2i-footer-button ion-icon {width: 100%; /*float: left;*/ margin-bottom: 5px; height: 24px; max-height: 24px; max-width: 28px; /*height: 100%;*/}
.c2i-footer-button ion-label {width: 100%; float: left; font-size: 13px !important; font-weight: 500; color: #8ea1a8 !important; /*margin-top: 5px;*/}
.c2i-footer-button ion-label.c2i-button-selected {color: #fff !important;}
#c2i-desktop-footer {text-align: center; height: 120px; background-color: #000; padding-top: 15px; display: none !important;}
#c2i-desktop-footer strong {font-size: 14px;}
#c2i-desktop-footer a {color: #fff !important; text-decoration: none;}
/** FOOTER - FINE **/


/** POPOVER - INIZIO **/
.popover-content {width: 90% !important; top: 50% !important; left: 50% !important; transform: translateX(-50%) translateY(-50%) !important; border-radius: 3px !important; max-width: 350px !important; min-height: 500px !important; background-color: transparent !important;}
.popover-arrow {display: none !important;}
.c2i-popover-grid {padding: 20px 10px 10px 10px; background-color: #fff; min-height: 500px;}
.c2i-altromenu-popover {bottom: 70px;}
.c2i-altromenu-popover .popover-content {width: 100% !important; bottom: -93px !important; top: auto !important; border-radius: 0 !important; max-width: 100% !important; min-height: auto !important; height: auto !important; background-color: #282F31 !important;}
.c2i-accedimenu-popover {bottom: 90px;}
.c2i-accedimenu-popover .popover-content {width: 100% !important; bottom: -117px !important; top: auto !important; border-radius: 0 !important; max-width: 100% !important; min-height: auto !important; height: auto !important; background-color: #282F31 !important;}
.popoverBottomGrid {padding: 25px 15px 35px 15px;}
.popoverBottomItem {border-bottom: 1px solid rgba(142, 161, 168, .25); padding: 10px 0; font-weight: 600; font-size: 18px; text-align: left; color: #fff; cursor: pointer;}
/*.popover-content.sc-ion-popover-md {max-width: 330px; min-height: 450px;}
.popover-content sc-ion-popover-ios {max-width: 330px; min-height: 450px;}
/** POPOVER - FINE **/

.c2i-img-full-container-slider ion-slide {width: 100% !important;}

#c2i-toolbar-header {display: none !important;}
ion-footer {display: block !important;}
@media (min-width: 1100px) {
    #c2i-toolbar-header {display: block !important; max-width: 1100px; margin: 0 auto;}
    #c2i-desktop-footer {display: block !important; font-size: 12px;}
    .c2i-altromenu-popover {bottom: 0; top: 0;}
    .c2i-altromenu-popover .popover-content {/*top: 195px !important;*/top: 219px !important; bottom: auto !important; width: 25% !important; right: 0 !important; left: auto !important;}
    .c2i-accedimenu-popover {bottom: 0; top: 0;}
    .c2i-accedimenu-popover .popover-content {top: 215px !important; bottom: auto !important; width: 25% !important; right: 16% !important; left: auto !important;}
    ion-footer {display: none !important;}
    .c2i-img-full-container-home {display: block;}
    .c2i-img-full-container-slider {display: none;}
    .c2i-img-full-container {text-align: center; margin-top: 45px;}
    /*.c2i-img-banner-white {margin-top: 45px; max-width: 1100px; border-top-left-radius: 10px; border-top-right-radius: 10px; margin: 0 auto; background-color: transparent;}*/
    .c2i-img-full-container {margin-top: 0;}
    .c2i-img-banner-white {margin-top: 45px; max-width: auto; border-top-left-radius: 10px; border-top-right-radius: 10px; margin: 0 auto; background-color: transparent; max-height: 230px;}
    .c2i-home-grid {max-width: 1100px; margin-bottom: 45px;}
    .c2i-page-grid {max-width: 1100px; margin-bottom: 45px;}
    .popover-content {max-width: 550px !important;}
    .c2i-piatto-img-col {max-width: 90px !important; /*max-width: 250px !important;*/}
    #c2i-toolbar-header .c2i-footer-button ion-label {font-size: 16px !important; margin-top: 0;}
    .c2i-hide-mobile {display: block;}
    .c2i-grid-menu-desktop {max-width: 900px; margin: 0 auto;}

    .c2i-ordine-item-container {padding: 15px 5px;}
    .c2i-piatto-desc-horizontal {min-height: auto; margin-bottom: 0;}
    .c2i-img-contain {height: auto;}
    .c2i-ordine-item-container-top {margin-bottom: 0 !important;}
}
/*@media (min-width: 768px) {
    .c2i-img-full-container {text-align: center; margin-top: 45px;}
    .c2i-img-banner-white {margin-top: 45px; max-width: 768px; border-top-left-radius: 10px; border-top-right-radius: 10px; margin: 0 auto; background-color: transparent;}
    .c2i-home-grid {max-width: 768px; margin-bottom: 45px;}
    .c2i-page-grid {max-width: 768px; margin-bottom: 45px;}
    .popover-content {max-width: 550px !important;}
}*/